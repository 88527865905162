import { useContext, useEffect, useState } from 'react';
import { IErrorResponseData, IResponse } from 'interfaces/api';

import NoteModalForm from 'components/forms/NoteModalForm';
import { OrderAPI } from 'api/orderAPI';
import { BaseListActionsContext } from 'context/BaseListActions';
import { LinkButton } from 'components/UIComponents';
import { ToastType } from 'constants/enums/PopupType';
import { ToastContext } from 'context/ToastContext';
import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { usePopup } from 'context/GlobalPopupContext';

import RESPONSE_CODES from 'constants/responseCodes';
import { apiErrorToPopupData } from 'helpers/utils';

const SendReminderAction = () => {
  const { onToastOpen } = useContext(ToastContext);
  const { reloadList } = useContext(BaseListActionsContext);
  const [storedNote, setStoredNote] = useState<string | undefined>(undefined);
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { onModalOpen } = useContext(ModalContext);
  const { openPopup } = usePopup();

  const openConfirmPopup = () => {
    onModalOpen('REMINDER_MODAL_FORM_SEND_ORDERS');
  };

  const forceProceed = () => {
    handleRequest(true, storedNote as string);
  };

  const handleSend = (note: string | undefined) => {
    setStoredNote(note);
  };

  useEffect(() => {
    if (storedNote !== undefined) {
      handleRequest(false, storedNote);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedNote]);

  const handleRequest = (force: boolean = false, note: string) => {
    OrderAPI.sendReminderOrders({ selectAll, ids, excludedIds }, getSerializedData(), force, note)
      .then(handleResponse);
  };

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.OK) {
      reloadList();
      const ordersCount = response.data.data.length;
      if(ordersCount === 1) {
        onToastOpen('ORDER_BULK_ACTION_SUCCESS', 'Order Reminder has been successfully sent.',
          ToastType.success);
      }
      else {
        onToastOpen('ORDER_BULK_ACTION_SUCCESS', `${ordersCount} Order Reminders have been successfully sent.`,
          ToastType.success);
      }
      setStoredNote(undefined);
    } else {
      let popupData = apiErrorToPopupData((response.data as IErrorResponseData).error);
      openPopup({
        ...popupData,
        submitCallback: forceProceed,
      });
    }
  };

  return (
    <>
      <LinkButton onClick={openConfirmPopup}>Send Reminder</LinkButton>
      <NoteModalForm onSubmit={handleSend} modalName='REMINDER_MODAL_FORM_SEND_ORDERS'/>
    </>
  );
};

export default SendReminderAction;
