import { useContext } from 'react';

import { Form, Formik } from 'formik';

import { OutlinedButton, SubmitButton, TextArea } from 'components/UIComponents';
import { FieldGroupTitleContainer } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';
import { ModalContext } from 'context/ModalContext';
import { commentValidationSchema } from 'helpers/validation/commentValidationSchema';

import Modal from 'components/base/BaseModal';

export const NOTE_MODAL_FORM = 'NOTE_MODAL_FORM';

const modalTitles = {
  'REMINDER_MODAL_FORM_SEND_ORDERS': 'Email Reminder Note',
};

interface Props {
  onSubmit: (note?: string, status?: string) => void,
  modalName?: string,
}
const NoteModalForm = ({
                            onSubmit,
                            modalName = NOTE_MODAL_FORM,
                          }: Props) => {
  const { onModalClose } = useContext(ModalContext);

  const onModalSubmit = (note?: string) => {
    onModalClose(modalName);
    onSubmit(note);
  };

  return (
    <Modal
      name={modalName}
      title={modalTitles[modalName as keyof typeof modalTitles]}
      onModalClose={(_: string) => onModalSubmit()}
    >
      <Formik
        initialValues={{ note: '' }}
        enableReinitialize
        validationSchema={commentValidationSchema}
        validateOnChange
        onSubmit={(values) => onModalSubmit(values.note)}
      >
        {({ errors, handleSubmit }) => {
          return (
            <Form>
              <DialogContent>
                <FieldGroupTitleContainer>NOTE</FieldGroupTitleContainer>
                <TextArea name="note" placeholder="Please leave your note here" />
              </DialogContent>
              <DialogActions>
                <OutlinedButton onClick={onModalSubmit}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={!!Object.values(errors).length}
                  onClick={handleSubmit}
                >
                  Proceed
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default NoteModalForm;
