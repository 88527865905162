import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import SortOptionsModal from '../SortOptions';
import { ModalContext } from '../../../../context/ModalContext';

import { LinkButton, ButtonsBlock, CustomIcon } from 'components/UIComponents';
import UploadButton from 'components/base/UploadButton';
import SelectAllCheckbox from 'components/base/tableComponents/SelectAllCheckbox';

import {
  PanelContainer,
  StyledFormGroup as FormGroup,
  StyledFormControlLabel as FormControlLabel,
  LinkButtonText,
} from './styledComponents';

interface Props {
  onAddNewClick: () => void;
  showSelectAll: boolean;
}

const SORT_FORM_MODAL_NAME = 'sort_form_modal';

const ActionsPanel = ({ showSelectAll, onAddNewClick }: Props) => {
  const navigate = useNavigate();
  const [isSortModalOpen, setSortModalOpen] = useState(false);
  const { onModalOpen } = useContext(ModalContext);

  return (
    <PanelContainer>
      <FormGroup style={{flexDirection: 'row', marginTop: 3}}>
        {showSelectAll ? <FormControlLabel control={<SelectAllCheckbox />} label="Select All" /> : ''}
        <LinkButton onClick={() => {setSortModalOpen(true); onModalOpen(SORT_FORM_MODAL_NAME);}}>
          <LinkButtonText>Sort Options</LinkButtonText>
        </LinkButton>
      </FormGroup>

      <ButtonsBlock>
        <LinkButton onClick={onAddNewClick}>
          <CustomIcon name="plus" color="inherit" size="x-small" />
          <LinkButtonText>Add new spot</LinkButtonText>
        </LinkButton>
        <UploadButton title="Upload spots" onClick={() => navigate('spot-import-monitor')} />
      </ButtonsBlock>

      {isSortModalOpen && <SortOptionsModal onClose={() => setSortModalOpen(false)} />}
    </PanelContainer>
  );
};

export default ActionsPanel;
