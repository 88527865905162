import { FormEvent, useContext } from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import { Form, Formik } from 'formik';

import {
  LinkButton,
  SearchableMultiSelect,
  SubmitButton,
  ButtonsBlock,
  Checkbox,
  DatePickerGroup,
} from 'components/UIComponents';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';

import {
  getGroupEstimateOptions,
  getMarketOptions,
  getGroupOutletOptions,
  getGroupProductOptionsForOrder,
  getGroupWeekStartOptions,
} from 'helpers/collections';
import { nonEmptyValues } from 'helpers/utils';

import { statusOptions } from 'constants/orders';

import { IFilterOptions } from 'interfaces/Order/IFilterOptions';

import { controllWrapperStyles } from './styledComponents';

const emptyValues = {
  isRevised: false,
  statuses: [],
  productsIds: [],
  estimates: [],
  outletsIds: [],
  weekStarts: [],
  markets: [],
  lastSentDateFrom: '',
  lastSentDateTo: '',
};

const GroupOrderFilterForm = () => {
  const { filterOptions, onFilter } = useContext(FilterContext);
  const { onChangeSelectAll } = useContext(BulkSelectContext);

  const initital = filterOptions as IFilterOptions;

  const initialValues = {
    isRevised: initital.isRevised || false,
    statuses: initital.statuses || [],
    outletsIds: initital.outletsIds || [],
    markets: initital.markets || [],
    productsIds: initital.productsIds || [],
    weekStarts: initital.weekStarts || [],
    estimates: initital.estimates || [],
    lastSentDateFrom: initital.lastSentDateFrom || '',
    lastSentDateTo: initital.lastSentDateTo || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        onChangeSelectAll(false);
        const present = nonEmptyValues(values);
        if (present.isRevised === false) delete present.isRevised;

        onFilter(present);
      }}
    >
      {({ values, handleSubmit, resetForm }) => {
        return (
          <Form>
            <Grid container columns={14} rowSpacing={2} columnSpacing={{ xs: 2, lg: 3 }}>
              <Grid xs={2}>
                <SearchableMultiSelect
                  name="statuses"
                  label="Status"
                  initial={initialValues.statuses}
                  getOptionsList={() => Promise.resolve(statusOptions)}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <SearchableMultiSelect
                  name="outletsIds"
                  label="Outlet"
                  initial={initialValues.outletsIds}
                  getOptionsList={getGroupOutletOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <SearchableMultiSelect
                  name="markets"
                  label="Market"
                  initial={initialValues.markets}
                  getOptionsList={getMarketOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <SearchableMultiSelect
                  name="productsIds"
                  label="Product"
                  getOptionsList={getGroupProductOptionsForOrder}
                  initial={initialValues.productsIds}
                  styles={controllWrapperStyles}
                  emptyOptionLabel="Any Product"
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <SearchableMultiSelect
                  name="weekStarts"
                  label="Week"
                  initial={initialValues.weekStarts}
                  getOptionsList={getGroupWeekStartOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <SearchableMultiSelect
                  name="estimates"
                  label="Estimate"
                  initial={initialValues.estimates}
                  getOptionsList={getGroupEstimateOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <Checkbox
                  styles={{
                    wrapper: {
                      margin: '0 !important',
                      marginTop: '18px !important',
                    },
                  }}
                  view="checkboxFirst"
                  name="isRevised"
                  label="Show Revised Only"
                />
              </Grid>
              <Grid xs={4}>
                <DatePickerGroup
                  startProps={{
                    name: 'lastSentDateFrom',
                    direction: 'column',
                    maxDate: values.lastSentDateTo,
                  }}
                  endProps={{
                    name: 'lastSentDateTo',
                    direction: 'column',
                    minDate: values.lastSentDateFrom,
                  }}
                  label='Last Sent'
                  connector='to'
                  styles={controllWrapperStyles}
                  direction='column'
                />
              </Grid>
              <Grid
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
                marginBottom="2px"
                marginTop="21px"
                xs={10}
              >
                <ButtonsBlock>
                  <LinkButton
                    onClick={() => {
                      resetForm({ values: emptyValues });
                      onChangeSelectAll(false);
                      onFilter({});
                    }}
                  >
                    Clear filter
                  </LinkButton>
                  <SubmitButton onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}>
                    Apply Filter
                  </SubmitButton>
                </ButtonsBlock>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GroupOrderFilterForm;
