import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Select, MenuItem, FormControl, InputLabel, Box, IconButton } from '@mui/material';
import { CustomIcon, LinkButton, OutlinedButton, SubmitButton } from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import Modal from 'components/base/BaseModal';
import { LinkButtonText } from '../ActionsPanel/styledComponents';
import { colors } from 'styles/globalStyles';
import { FilterContext } from '../../../../context/FilterContext';

const SORT_FORM_MODAL_NAME = 'sort_form_modal';
const MAX_SORTING = 2;

interface SortOptionsModalProps {
  onClose: () => void;
}

const sortOptions = [
  { value: 'outlet_asc', label: 'Outlet (asc)' },
  { value: 'outlet_desc', label: 'Outlet (desc)' },
  { value: 'start_date_asc', label: 'Start Date (asc)' },
  { value: 'start_date_desc', label: 'Start Date (desc)' },
];

const SortOptionsModal = ({ onClose }: SortOptionsModalProps) => {
  const [sortFields, setSortFields] = useState<string[]>(['outlet_asc']);
  const { filterOptions, onFilter } = useContext(FilterContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const filterParam = searchParams.get('filter') ? JSON.parse(searchParams.get('filter')!) : null;
    const sorts: string[] = [];

    if (filterParam) {
      Object.keys(filterParam)
        .filter((key) => key.startsWith('sort'))
        .sort((a, b) => a.localeCompare(b))
        .forEach((key) => {
          sorts.push(filterParam[key]);
        });
    }

    setSortFields(sorts.length > 0 ? sorts : ['outlet_asc']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddSort = () => {
    if (sortFields.length < MAX_SORTING) {
      const availableOptions = sortOptions.map((opt) => opt.value).filter((value) => !sortFields.includes(value));
      const newField = availableOptions[0];
      setSortFields([...sortFields, newField]);
    }
  };

  const handleRemoveSort = (index: number) => {
    setSortFields((prevSortFields) => prevSortFields.filter((_, i) => i !== index));
  };

  const handleChangeSort = (index: number, value: string) => {
    setSortFields((prevSortFields) => {
      const updatedFields = [...prevSortFields];
      updatedFields[index] = value;
      return updatedFields;
    });
  };

  const handleSave = () => {
    const updatedFilterOptions = { ...filterOptions } as Record<string, any>;

    Object.keys(updatedFilterOptions)
      .filter((key) => key.startsWith('sort'))
      .forEach((key) => delete updatedFilterOptions[key]);

    sortFields.forEach((field, index) => {
      updatedFilterOptions[`sort${index + 1}`] = field;
    });

    onClose();
    onFilter(updatedFilterOptions);
  };

  return (
    <Modal name={SORT_FORM_MODAL_NAME} title="Sort Options" onModalClose={onClose}>
      <DialogContent>
        {sortFields.map((field, index) => (
          <Box key={index} display="flex" alignItems="center" marginBottom={2}>
            <Box style={{ minWidth: 110, marginLeft: 10 }}>
              <InputLabel>{index === 0 ? 'Sort by' : 'Then sort by'}</InputLabel>
            </Box>
            <FormControl style={{ width: '70%' }}>
              <Select
                value={field}
                style={{height: 40}}
                onChange={(e) => handleChangeSort(index, e.target.value)}
              >
                {sortOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={sortFields.includes(option.value) && sortFields[index] !== option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {index > 0 && (
              <IconButton
                color="error"
                onClick={() => handleRemoveSort(index)}
                aria-label="Remove sort"
                style={{ marginLeft: 8 }}
              >
                <CustomIcon size="normal" name="trash" color={colors.black} />
              </IconButton>
            )}
          </Box>
        ))}
        <LinkButton onClick={handleAddSort} disabled={sortFields.length >= MAX_SORTING}>
          <LinkButtonText>Add New Sort</LinkButtonText>
        </LinkButton>
      </DialogContent>
      <DialogActions>
        <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
        <SubmitButton type="submit" onClick={handleSave}>Save</SubmitButton>
      </DialogActions>
    </Modal>
  );
};

export default SortOptionsModal;